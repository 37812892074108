import React from 'react';
import styles from './footer.module.scss';
import { Col } from 'react-bootstrap';
import logo from '../../assets/images/logo_white.svg';
import getContent from '../../utils/text-extractor';

export default function Footer() {

    return (
			<>
        <Col xs="16" className="p-0">
					<div className="content-wrapper h-100">
						<Col xs="14" className={`${styles.container__body} mb-5  p-0`}>
							<div className={styles.container__body__logo}>
								<img src={logo} alt="Bolttech" />
							</div>
						</Col>
						<Col xs="14" className={`pb-5 ${styles.container__copyright}  p-0`}>
							<div className="content-wrapper h-100">
								<div className={styles.container__copyright__text}>
									<span>
										{getContent('bolttech')} &#169; &nbsp;
										{getContent('bolttechRights')}
									</span>
								</div>
							</div>
						</Col>
					</div>
        </Col>
			</>
    )
}