import CONTENT_EN from './locales/en-us';
import CONTENT_PH from './locales/ph';
import { LANG_MAP } from './constants';

const getContent = (key) => {
  let language = LANG_MAP.EN_US;

  if (typeof window !== 'undefined') {
    language = window.sessionStorage.getItem('language');
  }

  switch (language) {
    case LANG_MAP.PH:
      return CONTENT_PH[key] || '';

    default:
      return CONTENT_EN[key] || '';
  }
};

export default getContent;
