import React from 'react';

const CONTENT = {
  welcome: 'Maligayang pagdating',
  email: 'Email',
  phone: 'Phone',
  confirm: 'Confirm',
  next: 'Next',
  done: 'Done',
  viewDetails: 'View Details',
  reqService: 'Request service',
  summary: 'Service Request Summary',
  answerError: 'Invalid Date selection',
  summaryDesc:
    'Please check these details are correct before submitting your service request',
  termsAndCondition:
    "By clicking 'Submit', I certify that this Service request form is submitted with my knowledge as the Subscriber and the information submitted is true and correct at the time of submission. I declare that I am accountable as the Subscriber for any information found to be false, misleading or misinterpreting.",
  submit: 'Submit',
  linkAccounts: 'I-link ang mga account',
  registered: "You're now registered.",
  validTill: 'Valid until',
  protectedDevices: 'Ang iyong mga protektadong aparato',
  linkPlans:
    'I-link natin ang lahat ng iyong mga plano sa proteksyon sa account na ito.',
  linkMorePlans: 'Naghahanap ng ibang kontrata?',
  bolttech: 'bolttech',
  bolttechRights: '2021 All rights reserved.',
  linkMyAccounts: 'Link my accounts',
  imeiNoMatch:
    'No matches found. Check the number or try an alternative option to link',
  linkMyPlanHeading: 'Link my protection plans',
  linkSuccess: 'Your device is now linked to this account',
  optionToLink: 'Choose an option to link',
  acknowledgement:
    'I hereby declare info true & correct at the time of submission and acknowledge the use of my personal data by AMS',
  imei: 'IMEI',
  imeiNumber: 'IMEI number',
  serialNo: 'Serial No',
  serialNumber: 'Serial number',
  contractNo: 'Contract No',
  contractNumber: 'Contract number',
  retry: 'Retry',
  edit: 'Edit',
  navDashboard: 'Dashboard',
  navContactUs: 'Contact Us',
  navTechSupport: 'Technical Support',
  navChangePassword: 'Change Password',
  navLogout: 'Logout',

  tooltipHeader: 'What is this for?',
  tooltipBody:
    'You may have purchased protection plans from multiple retailers and may have used different emails or phone numbers to register. This is a way for you to have all your plans in one place!',
  linkAccountsIsThisYouTitle: 'Is this you?',
  linkAccountsIsThisYouSubtitle:
    'Where would you like us to send a confirmation code?',
  linkAccountsSendOtp: 'Send confirmation code',
  linkAccountsConfirmOtp: 'Confirm',
  linkAccountsTryAgain: 'Try Again',
  linkAccountsEnterOtp: 'Enter your confirmation code',
  linkAccountsCodeSent: 'We’ve sent a security code to',
  linkAccountsNewCode: 'Need a new code?',
  linkAccountsWaitTime: 'Please wait ',
  policyAlreadyLinked: 'This is already linked',
  onlyActivePolicyLink: 'Only active policies can be linked',
  sendOtpErrorHeading: 'Something went wrong',
  wrongOtp: 'Invalid code',
  otpExpired: 'Code has expired',
  validateOtpApiError: 'Something went wrong. Please try again',
  sendOtpError:
    "We're unable to send you a code right now. Please use an alternative contact or try again later.",
  noContactHeading: 'No email or contact number are attached to the contract',
  noContactError:
    'Please contact to your customer care to get updated and try again to link the contract.',
  prepareYourDeviceHeading: 'Prepare phone',
  prepareYourDeviceTitle: 'Please prepare your phone for pick up',
  prepareYourDeviceRemovePasswordHeading: 'Remove passwords / locks',
  prepareYourDeviceRemovePasswordBody:
    'If your smartphone is not functional, please write the information down and pack it together with the device.',
  prepareYourDeviceLogoutAccountsHeading: 'Log out of personal accounts',
  prepareYourDeviceLogoutAccountsBody:
    'Log out of personal accounts such as iCloud, Gmail and any social media accounts.',
  prepareYourDeviceBackupDataHeading: 'Back up data',
  prepareYourDeviceBackupDataBody:
    'Backup or delete personal data such as images, videos and contacts',
  prepareYourDeviceRemoveSimHeading: 'Remove SIM',
  prepareYourDeviceRemoveSimBody:
    'Remove SIM cards and memory cards. We will not be responsible for any loss or damage to them. ',

  beforeBeginHeading: 'Before you begin',
  beforeBeginSubHeading:
    'Some supporting documents may be required to process your request.',
  beforeBeginTheftDocTitle: 'Request relating to Robbery',
  beforeBeginTheftDoc1Bold: 'A police report',
  beforeBeginTheftDoc1: ' from the local law authority',
  beforeBeginTheftDoc2Bold: 'Notice to network operator',
  beforeBeginTheftDoc2: ' to bar the SIM',
  beforeBeginTravelDocTitle: 'Incidents outside the country',
  beforeBeginTravelDoc1: 'At least one ',
  beforeBeginTravelDoc1Bold: 'travel document',
  beforeBeginAccidentDocTitle: 'Accidental damage',
  beforeBeginAccidentDoc1Bold: 'Photo',
  beforeBeginAccidentDoc1: ' of the damaged device',
  beforeBeginOkContinue: 'Okay, continue',

  question: 'Question',
  claimSubmittedRequestSubmitted: 'Request submitted',
  claimSubmittedHeading:
    'We will give you the update on your service request within 24 hours',
  claimSubmittedRefId: 'Ref. ID',
  claimSubmittedReturnToDashboard: 'Return to dashboard',

  uploadFilesTitle: 'Please upload the following documents',
  uploadFilesHeading: 'Upload documents',
  uploadFilesContent: 'File size limit is 10 MB',
  uploadFilesPopupTitle: 'The following documents are required:',
  uploadFilesAdd: 'Add files',
  uploadFilesDamagePhoto: 'Damage Photo',
  uploadFilesPoliceReport: 'Police report',
  uploadFilesPoliceReportDescription:
    'Please provide a police report from the local law authority.',
  uploadFilesNoticeToAuthority: 'Notice to authorities',
  uploadFilesNoticeToAuthorityDescription:
    'Please provide the proof of document sent to the network operator to bar the SIM.',
  uploadFilesTravelDocuments: 'Travel documents',
  uploadFilesTravelDocumentsDescription:
    'Please provide at least one travel document.\n' +
    'Eg Travel itinerary, Hotel bookings, Flight ticket or other (Visa, receipt and etc.)',
  uploadFilesKTP: 'KTP (Identity Card)',
  uploadFilesKTPSelfie: 'Photo Selfie with KTP',
  uploadFilesSimReplacementReceipt:
    'Sim card replacement receipt from the telco',
  uploadFilesSupportingDocuments: 'Supporting images of the incident',
  uploadErrorSizeTitle: 'File size exceeds limit',
  uploadErrorSizeContent:
    '10 MB max file size. Select a new file and try again.',
  uploadErrorNameTitle: 'File name is duplicate',
  uploadErrorNameContent:
    'The file you selected has a duplicate name. Select a new file and try again.',
  uploadErrorTypeTitle: 'File type is not allowed',
  uploadErrorTypeContent:
    'The file you selected is not of a valid type. Select a new file and try again.',
  uploadErrorFailedTitle: 'Unable to complete upload',

  pageErrorTitle: 'Something went wrong...',
  pageErrorMsg:
    "We're not exactly sure what happened, but our team is working to fix it.",

  goBack: 'Go Back',
  technicalSupportTosTitle:
    "By clicking 'Accept' you agree and consent to the following terms of use:",
  technicalSupportTosAccept: 'Accept',
  technicalSupportTosDecline: 'Decline',
  technicalSupportTosBody: (
    <>
      <ol style={{ listStyleType: 'lower-alpha' }}>
        <li>
          The use of the Tech Support (TS) website is exclusive to the customers
          of bolttech.
        </li>
        <li>
          The TS website is not a property of bolttech. Any update,
          modification, review, or changes in the content is outside the control
          of bolttech. Therefore, bolttech shall not be liable for any
          occurrence of failure or delay in the use or operation of the TS
          website.
        </li>
        <li>
          You agree to indemnify and hold harmless bolttech, its affiliates, and
          its officers from any and all losses or claims, actions, damages, or
          liabilities arising from your use of the TS Website, from an actual or
          alleged infringement of any patent, copyright, trademark, trade
          secret, or any other Intellectual Property rights under the TS
          website, or from any infringement of the policies contained therein.
        </li>
        <li>Your use of the TS website is at your own risk.</li>
      </ol>
    </>
  ),
};

export default CONTENT;
