import styles from './App.module.scss';
import ServiceRequest from "./pages/service-request/index";
import Header from './components/Header/index'
import Footer from './components/Footer/index'

function App() {
  return (
    <div className="App">
      <div className={`w-100 ${styles['page-header']}`}>
          <Header />
      </div>
      <div className={`w-100 ${styles['page-content']}`}>
        <ServiceRequest />
      </div>
      <div className={`w-100 ${styles['page-footer']}`}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
