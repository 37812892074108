import React, { useState } from 'react'

import DropDownComp from '../../components/DropDown/index'
import styles from './requestform.module.scss'

const RequestForm = () => {
    const selectPlan = 'Who is your plan with?'
    const selectCountry = 'Which country are you in?'
    const planFeedback = { wrong: `Please select ${selectPlan.toLowerCase()}`}
    const countryFeedback = { wrong: `Please select ${selectCountry.toLowerCase()}`}

    
    const links = {
        "Philippines": {
            "Home Credit": `${process.env.REACT_APP_PH_HOME_CREDIT_URL}`,
            'Others': `${process.env.REACT_APP_PH_OTHERS_URL}`,
        },
        "Indonesia": {
            'Home Credit': `${process.env.REACT_APP_ID_HOME_CREDIT_URL}`,
            'Telkomsel': `${process.env.REACT_APP_ID_TELKOMSEL_URL}`,
            'Erajaya (TecProtec proCLASSIC)': `${process.env.REACT_APP_ID_TELKOMSEL_URL}`,
            'Erajaya (TecProtec Others)': `${process.env.REACT_APP_ID_OTHERS_URL}`,
            'Others': `${process.env.REACT_APP_ID_OTHERS_URL}` 
        },
        "Vietnam": {
            'Home Credit': `${process.env.REACT_APP_VN_HOME_CREDIT_URL}`,
            'Phongvu': `${process.env.REACT_APP_VN_PHONGVU_URL}`,
            'MBW': `${process.env.REACT_APP_VN_MBW_URL}`,
            'Others': `${process.env.REACT_APP_VN_OTHERS_URL}` 
        },
        "Malaysia": {
            'Digi': `${process.env.REACT_APP_MY_DIGI_URL}`, 
            'TM': `${process.env.REACT_APP_MY_TM_URL}`,
            'Aeon': `${process.env.REACT_APP_MY_AEON_URL}`,
            'HSBC': `${process.env.REACT_APP_MY_HSBC_URL}`,
            'Others': `${process.env.REACT_APP_MY_OTHERS_URL}` 
        },
        'Singapore': {
            'SingTel': `${process.env.REACT_APP_SG_SINGTEL_URL}`
        },
        'India': {
            'Others': `${process.env.REACT_APP_IN_OTHERS_URL}` 
        },
        'Thailand': {
            'Others': `${process.env.REACT_APP_TH_OTHERS_URL}` 
        },
        'Hong Kong': {
            'Others': `${process.env.REACT_APP_HK_OTHERS_URL}`,
            'Elecboy': `${process.env.REACT_APP_HK_ELECBOY_URL}` 
        },
        'Taiwan': {
            'Others': `${process.env.REACT_APP_TW_OTHERS_URL}` 
        }
    }
    const defaultPlans = ['Home Credit', 'Others']
    const defaultVNPlans = ['Home Credit','Phongvu', 'MBW', 'Others']
    const countryPlanMap = {
        "Philippines" : defaultPlans,
        "Indonesia" : ['Home Credit', 'Telkomsel', 'Erajaya (TecProtec proCLASSIC)', 'Erajaya (TecProtec Others)', 'Others'],
        "Vietnam" : defaultVNPlans,
        "Malaysia": ['Aeon', 'Digi', 'HSBC', 'TM', 'Others'],
        "Singapore": ['SingTel'],
        "India": ['Others'],
        "Thailand": ['Others'],
        "Hong Kong": ['Others','Elecboy'],
        "Taiwan": ['Others'],
    }
    const countries = ['Philippines', 'Malaysia', 'Indonesia', 'Vietnam', 'Singapore', 'India', 'Thailand', 'Hong Kong', 'Taiwan']
    const [country, setCountry] = useState(0)
    const [plans, setPlans] = useState([])
    const [plan, setPlan] = useState(0)
    const [defaultCountry, setDefaultCountry] = useState(selectCountry)
    const [defaultPlan, setDefaultPlan] = useState(selectPlan)
    const [isCountryValid, setIsCountryValid] = useState(true)
    const [isPlanValid, setIsPlanValid] = useState(true)

    function handleChangeCountry(index) {
        setCountry(index)
        setPlans(countryPlanMap[countries[index]])
        setDefaultPlan(selectPlan)
        setDefaultCountry(countries[index])
        setIsCountryValid(true)
    }

    function handleChangePlan(index) {
        setPlan(index)
        setDefaultPlan(plans[index])
        setIsPlanValid(true)
    }

    function isFormValid () {
        let flag = false;
        if (defaultPlan !== selectPlan 
            && defaultCountry !== selectCountry) {
            flag = true;
        }
        
        return flag
    }

    function getLandingPageLink() {
        if (isFormValid()) {
            // window.open(links[countries[country]][plans[plan]])
            return links[countries[country]][plans[plan]]
        }

        return '#';
    }

    function validateForm(e) {
        let flag = true
        if (defaultPlan === selectPlan) {
            setIsPlanValid(false)
            flag = false
        }
        if (defaultCountry === selectCountry) {
            setIsCountryValid(false)
            flag = false
        }
        if (!flag) {
            e.preventDefault();
            return flag
        }
        setIsCountryValid(true)
        setIsPlanValid(true)
        return true;
    }

    return (
        <div className={`${styles.textmargin} primary-color `}>
            <div className='mb-4'>
                <p className={`${styles.textmargin__heading}`}><span className='light-font'>Need to submit a</span><br /><b>service request</b>?</p>
                <br />
                <h3>Let's get started.</h3>
            </div>
            <div>
                <div className='mb-31'>
                    <DropDownComp showErrorIcon={true} data={countries} feedback={countryFeedback} valid={isCountryValid} selected={country} handleChange={handleChangeCountry} defaultSelection={defaultCountry}  className='w-100'/>
                </div>
                <div className='mb-1'>
                    <DropDownComp showErrorIcon={true} data={plans} feedback={planFeedback} valid={isPlanValid} selected={plan} handleChange={handleChangePlan} defaultSelection={defaultPlan} className='w-100' />
                </div>
                <div className='mt-5'>
                    <a target="_blank" href={getLandingPageLink()} onClick={validateForm} className="portal-btn sp-btn-primary btn-space visit-portal"
                      rel="noreferrer noopener">
                         Request service
                         <svg width="14" height="14" viewBox="0 0 7 14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                  />
                </svg>
                </a>
                </div>
            </div>
        </div>
    )
}

export default RequestForm