import React from 'react'
import RequestForm from '../../components/RequestForm/index'
import { Col, Row } from 'react-bootstrap';
import styles from './service-request.module.scss'
import placeholderImg from '../../assets/images/placeholder-laptop.svg';

export default function ServiceRequest() {
    return (
			<div className="content-wrapper h-100">
				<div className="w-100 h-100">
					<Row className={`${styles.container} m-0 p-0`}>
						<Col xs="16" className={`${styles.container__body} mx-auto p-0`}>
							<span
								className={`${styles['container__body__navyblue-box']}  d-sm-block`}
							/>
							<span
								className={`${styles['container__body__small_white-box']}  d-sm-block`}
							/>
											<span
								className={`${styles['container__body__yellow-box']}  d-sm-block`}
							/>
						</Col>
						<Col xs="16" sm="4" className={`box-border flex ${styles.container__form}`}>
							<RequestForm />
						</Col>
						
						<Col xs="16" sm="6" className="p-0 mx-auto box-border flex">
							<div className={`${styles.image}`}>
								<img src={placeholderImg} alt="Bolttech" />
							</div>
						</Col>
						
					</Row>
				</div>
			</div>
	)

}