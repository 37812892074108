// COUNTRY
export const MY = 'MY';
export const VN = 'VN';
export const PH = 'PH';
export const ID = 'ID';
export const DATE_FORMAT = 'DD/MM/yyyy';
export const TIME_FORMAT = 'hh:mm A';
export const TIME_FORMAT_24 = 'HH:mm';
export const CRMZ_DATE_FORMAT = 'yyyy-MM-DD';

export const TECHNICAL_SUPPORT_PORTAL =
  'https://admin.helpforsmartphone.com/bolttech/en/devices/';
export const CONTACT_US_PAGE = 'https://device.bolttech.ph/service-request';

export const VALID_FILE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'xls',
  'csv',
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'msg',
];
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const LANG_MAP = {
  EN_US: 'en-us',
  PH: 'ph',
};

export const COUNTRY_CODES = {
  MY: 'MY',
  VN: 'VN',
  PH: 'PH',
  ID: 'ID',
};

/* Phone input has different country code then what we are using so this is to override phone input code */
export const PHONE_COUNTRY_CODE_MAPPING = {
  PH: '63',
  MY: '60',
  ID: '62',
  VN: '84',
  AT: '43',
  IN: '91',
};

export const PAGES = {
  DEVICES: '/devices',
  LINK_ACCOUNTS: '/linkaccounts',
  BEFORE_YOU_BEGIN: '/beforebegin',
  VERIFY_YOURSELF: 'linkaccounts/verifyidentity',
  VERIFY_OTP: '/linkaccounts/verifyotp',
  UPLOAD_DOCUMENTS: '/uploadDocuments',
  PREPARE_DEVICE: '/prepareYourDevice',
  QUESTIONS_SUMMARY: '/questionnaire/summary',
  CALIM_SUBMITTED: '/claimSubmitted',
  TERMS_CONDITIONS: '/termscondition',
  PRIVACY_POLICY: '/privacypolicy',
};
