import React from 'react';
import styles from './header.module.scss';
import { Col, Row } from 'react-bootstrap';
import logoImage from '../../assets/images/logo.svg';

export default function Header() {

	return (
		<div
				className="w-100 h-100"
				style={{ borderBottom: '1px solid #e5e5e5' }}
			>
				<div className="content-wrapper">
					<Col  sm="14" className={`${styles.logomargin} ml-auto`}>
						<Row className={`${styles.container} p-0 ${styles.mobile_header}`}>
							<Col sm="10" className={`${styles.mobile_header__lang_dd} p-0`}>
								<div className={styles.container__logo}>
									<a href="/">
										<img src={logoImage} alt="Bolttech" />
									</a>
								</div>
							</Col>
						</Row>
					</Col>
				</div>
		</div>
	)
}