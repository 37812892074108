import React from 'react'
import downarrow from '../../assets/images/arrow-down.svg';
import alertIcon from '../../assets/images/alert-icon.svg';
import styles from './dropdown.module.scss'
import {Dropdown, ButtonGroup} from 'react-bootstrap'

const DropDownComp = (props) => {

    function handleChange(e, index) {
        e.preventDefault()
        props.handleChange(index)
    }

    return (
        <>
        <Dropdown as={ButtonGroup} className={`${styles.dropdown}`}>
            <Dropdown.Toggle split variant="" id="dropdown-split-basic" className={props.valid === false ? `${styles[props.size]} ${styles.invalidDropdown}` : `${styles[props.size]}`}>
                <span className='ph-2'>{props.defaultSelection}</span> 
                {props.showErrorIcon && (props.valid !== true) &&
                    <span className={`${styles.rightAlertIcon}`}>
                        <img src={alertIcon} alt='alert icon' width="20" height="20" />
                    </span>
                }
                <span className={`${styles.right}`}>
                    <img src={downarrow} alt='dropdown-arrow' width={props.size === 'small' ? 10 : null} height={props.size === 'small' ? 10 : null} />
                </span>
            </Dropdown.Toggle>
            
            <Dropdown.Menu className={props.className}>
                {
                    props.data.map((elem, index) => <Dropdown.Item key={elem} onClick={(e) => handleChange(e, index)}>{elem}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
        {props.valid === false ? <p className={styles.invalid}>{props.feedback.wrong}</p> : null}
        </>
    )
}

export default DropDownComp